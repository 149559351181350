'use client'
import type { FC } from 'react'
import { useCallback } from 'react'
import { DynamicWidgetProps, StorefrontWidget, StoreType } from 'ecosystem'
import ProductListWidget, {
  ProductListWidgetType
} from 'storefront-modules/products/widgets/ProductListWidget'
import { ProductListVerticalWidgetType } from 'storefront-modules/products/widgets/ProductListVerticalWidget'
import { ProductListVerticalWidget } from 'storefront-modules/products'
import { useSelector } from 'shared-redux'

import appConfig from '#/src/appConfig'
import { useCustomCart } from '#/src/custom/cart/useCustomCart'
import { useSlideOut } from '#/src/custom/controllers'
import { ISlideOutMoreFiltersProps } from '#/src/custom/controllers/slideouts/SlideOutMoreFilters'
import { ISlideOutMoreFilters1 } from '#/src/custom/controllers/slideouts/SlideOutMoreFilters1'
import useProductRequest from '#/src/custom/products/useProductRequest'
import { selectedProductState } from 'shared-redux/state'
import { OverridesContextProvider, OverridesContextType } from 'ui/lib/overrides'
import CustomProductListItem from '#/src/custom/products/CustomProductListItem'
import { CustomProductList } from '#/src/custom/products/CustomProductList'

export type CustomProductListWidgetType = 'PRODUCT_LIST' | 'PRODUCT_LIST_1'

interface CustomProductListWidgetProps {
  type: CustomProductListWidgetType
  widget: StorefrontWidget<ProductListWidgetType | ProductListVerticalWidgetType>
  overrides?: OverridesContextType
  dynamicProps?: DynamicWidgetProps
}

const CustomProductListWidget: FC<CustomProductListWidgetProps> = ({
  dynamicProps,
  overrides,
  widget,
  type
}) => {
  const productsState = useSelector(selectedProductState)
  const sortingOptions = appConfig.sortingOptions
  const sliderMaxPrice = appConfig.sliderMaxPrice
  const sliderDefaultValues = appConfig.sliderDefaultValues
  const { handleAddToCart } = useCustomCart()
  const { closeSlideOut, openSlideOut } = useSlideOut()
  const { handleProductRequest } = useProductRequest()
  const apiPath = '/api/products/get-initial-products'

  const openMoreFilters = useCallback(() => {
    openSlideOut<ISlideOutMoreFiltersProps | ISlideOutMoreFilters1>({
      title: 'Filter',
      componentId: type === 'PRODUCT_LIST' ? 'moreFilters' : 'moreFilters1',
      drawerProps: {
        placement: 'right',
        size: 'md'
      }
    })
  }, [openSlideOut, type])

  return (
    <OverridesContextProvider overrides={{ ...overrides }}>
      {(() => {
        switch (type) {
          case 'PRODUCT_LIST': {
            return (
              <OverridesContextProvider
                overrides={{
                  showMoreFilters: 'Visa fler filter'
                }}>
                <ProductListWidget
                  showsPrice={appConfig.storeType === StoreType.MARKETPLACE}
                  customComponents={{
                    ProductList: CustomProductList,
                    ProductListItem: CustomProductListItem
                  }}
                  filterProps={{
                    sortingOptions,
                    sliderMaxPrice,
                    sliderDefaultValues,
                    filtersBeforeShowMore: appConfig.filtersBeforeShowMore
                  }}
                  widget={widget as StorefrontWidget<ProductListWidgetType>}
                  widgetId={widget.widget.name}
                  {...{
                    dynamicProps,
                    productsState,
                    onAddToCart: handleAddToCart,
                    onMore: openMoreFilters,
                    onProductRequest: handleProductRequest,
                    paths: {
                      getInitialProducts: apiPath,
                      productsUrlPath: appConfig.paths.productsUrlPath
                    }
                  }}
                />
              </OverridesContextProvider>
            )
          }

          case 'PRODUCT_LIST_1': {
            return (
              <ProductListVerticalWidget
                showsPrice={appConfig.storeType === StoreType.MARKETPLACE}
                customComponents={{
                  ProductListItem: CustomProductListItem
                }}
                widget={widget as StorefrontWidget<ProductListVerticalWidgetType>}
                widgetId={(widget as StorefrontWidget<ProductListVerticalWidgetType>).widget.name}
                {...{
                  dynamicProps,
                  productsState,
                  sortingOptions,
                  onAddToCart: handleAddToCart,
                  onMore: openMoreFilters,
                  onSearchUpdate: closeSlideOut,
                  sliderMaxPrice,
                  sliderDefaultValues,
                  onProductRequest: handleProductRequest,
                  paths: {
                    getInitialProducts: apiPath,
                    productsUrlPath: appConfig.paths.productsUrlPath
                  }
                }}
              />
            )
          }

          default:
            return null
        }
      })()}
    </OverridesContextProvider>
  )
}

export default CustomProductListWidget
